@media screen and (min-width: 601px) {
  @import "./carousel";
  #app {
    max-width: unset;
    //   min-width: 1046
  }

  .lg-only {
    display: none !important;
  }

  header,
  footer,
  .search-page,
  .item-view {
    max-width: unset;
    min-width: 860px;
  }

  .mobile-only {
    display: none !important;
  }
  header {
    .menu-icon {
      border: 0.6px solid #797979;
      padding: 7px 8px;
      border-radius: 3px;

      &:hover {
        border-color: #fff;
      }

      img {
        display: block;
      }
    }

    .logo {
      img {
        width: 96px;
        margin-top: 8px;
      }
    }

    .delevery-bar {
      background: $skin-med;
    }

    .dt-nav {
      li {
        font-size: 13px;
        color: #ccc;
      }
    }

    .bar {
      input {
        border-radius: 0;
      }
      select {
        border-radius: 3px 0 0 3px;
        border: 0;
        padding-left: 5px;
        padding-right: 5px;
        background: #f3f3f3;
        color: #555555;
        border-right: 1px solid #c7c7c7;
      }
      button {
        padding: 5px 15px;
      }
    }

    .dt-account-wrapper {
      display: flex;

      .lang-wrapper {
        border-right: 1px solid #ccc;
        padding-right: 10px;
        margin-right: 10px;
        div {
          display: flex;
          align-items: flex-end;

          img {
            display: block;
            width: 20px;
            height: 20px;
            margin-right: 5px;
          }
        }
      }
      ul {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: flex-end;

        li {
          margin-right: 24px;
          div {
            display: flex;
            align-items: flex-end;

            i {
              margin-left: 5px;
            }
          }
        }
      }
    }
  }

  main {
    max-width: 1500px;
    min-width: 860px;
    margin: 0 auto;

    .with-ad {
      display: flex;
      overflow: hidden;
      align-items: center;

      .body-section {
        width: 100%;
        margin-right: 10px;
      }
    }

    .body-section {
      position: relative;
    }
    .body-section-content,
    .body-section-content-flex {
      display: flex;
      justify-content: space-between;

      .card-item {
        div {
          .image-holder {
            width: 200px !important;
            height: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            &::after {
              content: " ";
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              background-color: #000;
              opacity: 0.1;
              display: none;
            }
            &:hover {
              &::after {
                display: block;
              }
            }
            img {
              // width: 150px !important;
              // height: 150px !important;
              // max-width: 150px !important;
              // max-height: 150px !important;
              max-width: unset !important;
              max-height: unset !important;
              height: 100%;
            }
          }
          div:last-child() {
            display: none;
          }
        }
      }
    }

    .body-section-content-flex {
      height: 200px;
      overflow: hidden;

      .card-item {
        width: unset;
        height: 200px;

        div {
          width: 200px;
        }

        h4 {
          display: none;
        }
      }
    }

    .body-section-footer {
      display: none;
    }
    .body-section-content-flex div.card-item.grid_9 {
      width: unset !important;
      height: 200px;
    }
  }

  .search-page {
    max-width: 1500px;
    min-width: 860px;
    margin: 0 auto;

    .content {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-evenly;
    }
    .item-wrapper {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 30%;
      height: unset !important;
      margin: 10px;

      .image-holder {
        height: 200px;
        width: 100% !important;
        margin-bottom: 25px;

        img {
          height: 100%;
          max-width: unset !important;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .dt-only,
  .lg-only {
    display: none !important;
  }
}

@media screen and (min-width: 1200px) {
  .lg-only {
    display: block !important;
  }
}
