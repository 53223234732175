@keyframes opacity {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}

.carousel-control {
  width: 50px;
  background: #d2d2d233;
  height: 30%;
  position: absolute;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 38%;
  opacity: 0.2;

  &:hover {
    box-shadow: 6px 6px 5px -5px #888;
    animation-name: opacity;
    animation-duration: 0.5s;
  }
}

.carousel-control.left {
  left: 0;
}
.carousel-control.right {
  right: 0;
}
