.search-page {
  .buttons_container {
    display: flex;
    flex-direction: row;
    padding: 1rem;
    background: #fff;
    font-family: Arial, sans-serif;
    .disabled_button {
      color: #0f1111 !important;
      border-radius: 0.3rem;
      background: #fff !important;
      background: -webkit-linear-gradient(top, #f7f8fa, #e7e9ec);
      background: linear-gradient(to bottom, #f7f8fa, #e7e9ec);
      border: none;
      font-size: 1.6rem;
      line-height: 1.35;
      padding: 1.3rem 1.6rem 1.2rem 1.7rem;
      width: 50%;
      margin-right: 0.5rem;
    }
    .previous_button {
      color: #0f1111 !important;
      border-radius: 0.3rem;
      background: #eff1f3;
      background: -webkit-linear-gradient(top, #f7f8fa, #e7e9ec);
      background: linear-gradient(to bottom, #f7f8fa, #e7e9ec);
      border: 0.1rem solid #6c6e73;
      border-color: #adb1b8 #a2a6ac #8d9096;
      font-size: 1.6rem;
      line-height: 1.35;
      padding: 1.3rem 1.6rem 1.2rem 1.7rem;
      width: 50%;
      margin-right: 0.5rem;
    }
    .next_button {
      color: #0f1111 !important;
      border-radius: 0.3rem;
      background: #eff1f3;
      background: -webkit-linear-gradient(top, #f7f8fa, #e7e9ec);
      background: linear-gradient(to bottom, #f7f8fa, #e7e9ec);
      border: 0.1rem solid #6c6e73;
      border-color: #adb1b8 #a2a6ac #8d9096;
      font-size: 1.6rem;
      line-height: 1.35;
      padding: 1.3rem 1.6rem 1.2rem 1.7rem;
      width: 50%;
      margin-left: 0.5rem;
    }
  }
  .bar {
    display: flex;
    background-color: #fff;
    margin-bottom: 5px;
    height: 40px;
    align-items: center;
    padding: 5px 10px;
    justify-content: space-between;

    .results-count {
      width: 60%;
    }
    .image-holder,
    .filter {
      padding-left: 12px;
      border-left: 2px solid #dee2e6;
      display: flex;
      height: 100%;
      align-items: center;

      h2 {
        color: #007185;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 15px;
        font-weight: 100;
      }
      .arrow {
        color: #007185;
        font-size: 1.5rem;
      }
    }

    .image-holder {
      img {
        width: 100px;
        display: block;
      }
    }

    .filter {
      align-items: flex-end;
      i {
        margin-left: 5px;
        color: #ababab;
        font-size: 20px;
      }
    }
  }

  .ad {
    display: flex;
    justify-content: space-around;
    padding: 0 0 6px 0;
  }

  .content {
    padding: 0 10px;
    background: #fff;
    .results_heading {
      h2 {
        letter-spacing: -1px;
        font-size: 16px;
        font-family: arial;
        font-weight: 700;
        color: #565959 !important;
      }
      p {
        font-size: 13px;
        color: #565959 !important;
        white-space: nowrap;
        // letter-spacing: -0.5px;
        font-family: Arial, Helvetica, sans-serif;
        opacity: 0.6;
      }
    }
    .main_div {
      display: flex;
      flex-direction: row;
      .item-wrapper {
        color: #111;
        display: flex;
        padding: 4px 0;
        background: #fff;
        height: auto;

        .image-holder {
          width: 40%;
          max-height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 0.5px solid #f1f1f188;
          background-color: #f5f3f398;
          img {
            max-width: 100%;
            max-height: 16.5rem;
            padding: 16px 2px;
          }
        }
        .data {
          width: 60%;
          background-color: #fbfdfd;
          border: 0.5px solid #f1f1f1a2;
          padding: 4px 6px;
        }
      }
    }

    .description {
      font-size: 16px;
      font-family: Arial, Helvetica, sans-serif;
      color: #565959;
      font-weight: 500;
      letter-spacing: 0.4px;

      text-overflow: ellipsis;
      word-wrap: break-word;
      overflow: hidden;
      max-height: 6em;
      line-height: 1.5em;
    }
    .sold-by {
      font-size: 12px;
    }
    .rating-holder {
      display: flex;
      align-items: center;

      .rating {
        margin-right: 5px;
        color: #ff9900;
      }
      .feedback {
        font-size: 13px;
        color: #565959;
        opacity: 0.8;
      }
    }
    .price {
      font-size: 18px;
      font-weight: bold;
      font-family: arial, Helvetica;
      .dollar {
        top: -3.9px;
        font-weight: 100;
        font-size: 12px;
        padding-right: 0.2px;
      }
      .cent {
        top: -3.9px;
        font-weight: 100;
        font-size: 12px;
        padding-left: 0.2px;
      }
    }
    .prime-image-holder {
      padding-right: 5px;
      margin-right: 5px;
      border-right: 1px solid #dee2e6;
      img {
        width: 55px;
      }
    }
    .ship_location {
      color: #565959;
      opacity: 0.7;
    }
    .ages {
      color: #565959;
      opacity: 0.9;
      font-family: Arial, Helvetica, sans-serif;
    }

    .delivery {
      display: flex;
      align-items: center;
      margin-top: 5px;

     
      .days {
        font-size: 12px;
      }
    }
  }
}
