main {
  background: $grey;
  position: relative;
}

.ad {
  display: flex;
  align-items: center;
  justify-content: center;
  img{
    width: 100%;
  }
}

.numGrid {
  position: fixed;
  z-index: 10;
  top: 185;
  left: 0;
  width: 100%;
  height: 80vh;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .num-grid-item {
    width: 33%;
    height: 25%;
    opacity: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    font-weight: bold;
    color: transparent;
  }
}

.body-section {
  background: #fff;
  padding: 5px 15px;
  margin-bottom: 5px;
  overflow: hidden;

  hr {
    background: #e3e3e3;
    height: 1px;
    border: 0;
  }
}

.body-section-header {
  h3 {
    font-size: 20px;
    font-weight: 300;
    line-height: 25px;
  }
}

.body-section-content {
  margin-top: 10px;
}

.body-section-content-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 10px;

  div.card-item {
    width: 49%;
    margin-bottom: 10px;
  }
  div.card-item.grid_9 {
    width: 32.6%;
    height: 180px;
  }
}

.body-section-footer {
  a {
    color: $blue;
  }
}
