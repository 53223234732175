.item-view {
  padding: 10px;

  .cta-holder {
    margin: 25px 0;
  }
  .cta {
    display: block;
    width: 100%;
    padding: 14px;
    font-weight: bold;
    background-color: #f2ca69;
    border: 1px solid #ad904b;
    border-radius: 3px;
  }

  .rating {
    color: #ecb000;
  }

  .feedback {
    color: $blue;
  }
  
  .item-wrapper {
    background: #fff;
    padding: 5px;
    .top {
      display: flex;
      justify-content: space-between;

      p {
        color: $blue;
      }
      .rating-holder {
        width: 40%;
        display: flex;
        justify-content: space-between;
      }
    }

    .image-holder {
      max-width: 100%;
      text-align: center;
      margin: 20px auto;

      img {
        max-width: 100%;
      }
    }

    .price {
      font-weight: bold;
      font-size: 20px;
    }

    .prime-image-holder {
      margin-bottom: 30px;
      img {
        width: 55px;
      }
    }

    .message {
      color: $blue;
      margin-bottom: 20px;
    }

    .delivery {
    }

    .purchace-options {
      border: 1px solid #d2d2ce;
      border-radius: 3px;
      padding: 10px;

      select {
        background: #efefef;
        padding: 2px 8px;
        margin-top: 10px;
      }
      .row {
        display: flex;
        margin-bottom: 20px;

        .radio {
          width: 5%;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          input {
          }
        }
        span {
          display: block;
        }
      }
    }
  }

  .proceed {
    .row,
    .card {
      display: flex;
    }
    .card {
      padding: 20px 0 0 0;

      .image-holder {
        margin: 0 20px 0 0;
      }

      .description {
        margin-bottom: 20px;
      }
      .price {
        margin-right: 20px;
      }
    }
    .buttons-holder {
      margin-bottom: 20px;
      * {
        margin-right: 20px;
        padding: 4px 10px;
        background-color: #f3f3f3;
        border: 1px solid #cccccc;
      }
    }
  }

  .place-your-order {
    .section {
      background: #fff;
      border-radius: 3px;
      margin-bottom: 20px;
    }
    .card {
      padding: 10px;
      border-bottom: 1px solid #ccc;
    }
    .row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
      align-items: center;
      .left {
      }
      .right {
      }
    }
    .last {
      margin-top: 10px;
    }
    .title {
      font-weight: bold;
      font-size: 24px;
    }
  }
}
