footer {
  color: #fff;

  p {
    font-size: 14px;
  }

  .section {
    display: flex;
    padding: 5px 20px;

    .nav-list {
      width: 50%;
      list-style: none;
      padding: 0;

      li {
        margin-top: 25px;
      }
    }
    .main_container{
      display: flex;
    .left,.center,
    .right {
      display: flex;
      justify-content: space-between;
      padding: 2rem 1.4rem;
    
     
    }
    

    .left{
      img{
        width: 1.4rem;
        margin-right: 0.85rem;
      }
      p{
        white-space: nowrap;
        color:#CCC;
        font-family:Arial, Helvetica, sans-serif;
        font-size: 14px;
        font-weight: 400;
  


      }
    }
    .center{
      img{
        width: 0.8rem;
        margin-right: 0.85rem;
      }
      p{
      white-space: nowrap;
      color:#CCC;
      font-weight: 400;

      font-family:Arial, Helvetica, sans-serif;
      font-size: 14px;



      }
    }
    
    .right{
      img{
        width: 2rem;
        margin-right: 0.85rem;
      }
      p{
        white-space: nowrap;
        font-weight: 400;

        color:#CCC;
        font-family:Arial, Helvetica, sans-serif;
        font-size: 14px;
  

      }
    }

    h3 {
      margin-bottom: 20px;
    }
  }

    .inlined-list {
      li {
        display: inline-block;
        margin-right: 15px;
        color: #CCC;
        font-size: 15px;
        
      }
    }
    .footer_bottom{
      color: #CCC;
      font-size: 15px;
    }
    .top_of_page{
      .heading{
        
        font-size: 12px;
      }
    }
  }
}
