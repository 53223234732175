* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
}

body {
  min-width: 400px;
  background: #eee;
  color: $dark-grey;
  font-family: Helvetica, Arial, sans-serif;
  font-size: $m-size;
  line-height: 1.6;
}

#app {
  max-width: 886px;
  margin: 0 auto;
}
button {
  cursor: pointer;
}

button:disabled {
  cursor: default;
}

.is-active {
  font-weight: bold;
}

a {
  color: unset;
  text-decoration: none;
}

ul {
  list-style: none;
}

.red {
  color: #bb0000;
}
.green {
  color: #43b100;
}
.blue {
  color: $blue;
}
