.carousel_main_container {
  display: flex;
  padding: 1rem 1rem;
  overflow: scroll;
  z-index: -1;
  margin-top: -11rem;
  background-color: #e8e4e47d;
  // .left_carousel {
  //   background-color: white;
  //   padding: 10px 8px;
  //   border-radius: 5px;
  //   max-height: 18rem;
  //   min-width: 24rem;

  //   h2 {
  //     font-size: 18px;
  //     font-family: Arial, Helvetica, sans-serif;
  //     font-weight: bold;
  //     color: #111111;
  //     opacity: 0.8;
  //   }
  //   p {
  //     color: #111111;
  //     font-size: 15px;
  //     font-family: Arial, Helvetica, sans-serif;
  //     padding-bottom: 2rem;
  //     letter-spacing: 0.4px;
  //     opacity: 0.7;
  //     white-space: nowrap;
  //   }
  //   button {
  //     margin-top: 3rem;
  //     background-color: #fcd735;
  //     padding: 3px 6px;
  //     border: none;
  //     border-radius: 6px;
  //     width: 100%;
  //     height: 3.8rem;
  //     color: #383636;
  //   }
  //   a {
  //     color: #007185;
  //     font-family: Arial, Helvetica, sans-serif;
  //     letter-spacing: 0.4px;
  //   }
  // }
  .right_carousel {
    margin-left: 4px;
    margin-right: 4px;
    max-width: 14rem;
    background-color: white;
    border-radius: 4px;
    p {
      // font-weight: bold;
      color: rgb(170, 170, 170);
      margin-left: 1rem;
      z-index: -1;
      line-height: 1.8rem;
      margin-top: -1.8rem;
      font-size: 14px;
      span{
        color: rgba(61, 60, 60, 0.705);
        font-weight: bold;
      }
    }
    img {
      width: 14rem;
      max-height: 18rem;
      border-radius: 5px;
    }
  }
}
