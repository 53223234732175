header {
  background: $skin-med;
  padding-top: 2.5px;
  
}

header .bar {
  color: #fff;
  padding: 5px 10px;
  min-height: 43px;
  display: flex;
  justify-content: space-between;
  

  .left,
  .right {
    display: flex;
    align-items: center;
    .account {
      display: flex;
      align-items: center;
      p {
        font-weight:bold ;
        font-size: 12.5px;

        span {
          font-size: 10px;
          margin-right: 4px;
          font-weight: 200;
        }
      }
      img {
        width: 2.2rem;
      }
    }
  }
  .menu-icon {
    margin-right: 15px;
padding-left: 5px;  }
img{
  width: 2rem;
}
.logo{
  img{
    width: 8.2rem;
  }
}

  .cart {
    margin-top: 5px;
    margin-left: 15px;
    position: relative;
    z-index: 10;
    padding-right: 15px;

    .cart-count {
      position: absolute;
      width: 100%;
      text-align: center;
      display: block;
      top: -10px;
      left: -4px;
      color: #f9ae51;
      font-weight: 700;
      z-index: 1;
    }
    img{
      width: 3.6rem;
    }
    
  }
  form {

    display: flex;
    width: 100%;
    height: 5rem;
    border-radius: 6rem ;
  }

  input {
    display: block;
    width: 100%;
    border: none;
    border-radius: 10px 0 0 10px;
    padding-left: 14px;
  
  }
  ::placeholder{
    font-stretch: normal;
    font-weight: initial;
    color: rgb(131, 126, 126);
  }
  button {
    background: $orange;
    padding: 3px 15px;
    border: none;
    border-radius:0 10px 10px 0;
    z-index: 1;
    img{
      width: 2.5rem;
    }
  }

  .navigation-menu {
    display: flex;
    margin: 5px 0px;
    flex-direction: row;


    li {
      list-style-type: none;
   
      white-space:nowrap;
      padding: 0px 8px;
    }
  }
  .pin-image-container {
    margin-right: 2px;
    img{
      width: 1.4rem;
      height: 1.75rem;
    }
    
  }
}

.delevery-bar {
  background: $skin-light;
  .left{
    display: flex;
    .pin-image-container{
      
        width: 1rem;
      }
      p{
        padding:0 0 3px 10px;
        font-size: 14px;
        font-weight: bold;

        
      }
    
  }
}

header p {
  font-size: 13px;
}
