// Colors
$orange: #fcbb6a;
$skin-med: #24303d;
$skin-light: #37475a;
$skin-dark: #131a22;
$dark-grey: #333;
$grey: #d5dbdb;
$blue: #1c88bf;
$dark-blue: #364051;
$off-white: #f7f7f7;
// Font Size
$font-size-large: 1.8rem;
$font-size-small: 1.4rem;
// Spacing
$s-size: 1.2rem;
$m-size: 1.6rem;
$l-size: 3.2rem;
$xl-size: 4.8rem;
$desktop-breakpoint: 45rem;
